import RECEIVED_DATA from '@/src/router/views/finance/fees/received/received.json'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (statsCount, currencySymbol) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: RECEIVED_DATA.TOTAL_RECEIVED_FEE_COUNT,
          value: statsCount?.total_received_fees_count || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: RECEIVED_DATA.TOOLTIP_REMAINING_FEE,
        },
        {
          text: RECEIVED_DATA.TOTAL_STUDENT_FULLY_PAID,
          value: statsCount?.fully_paid_fees_count || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: RECEIVED_DATA.TOOLTIP_TOTAL_STUDENT,
        },
        {
          text: RECEIVED_DATA.STUDENT_FEE_PARTIALLY_PAID,
          value: statsCount?.partially_paid_fees_count || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: RECEIVED_DATA.TOOLTIP_STUDENT_FEE_PARTIALLY_PAID,
        },
        {
          text: RECEIVED_DATA.TOTAL_FEE_RECEIVED,
          currency: currencySymbol,
          value: statsCount?.total_received_amount || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: RECEIVED_DATA.TOOLTIP_TOTAL_FEE_RECEIVED,
        },
        {
          text: RECEIVED_DATA.TOTAL_WAIVED,
          value: statsCount?.wavied_fees_count || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: RECEIVED_DATA.TOOLTIP_WAIVED_FEE,
        },
      ],
    },
  ]
  return stats
}
