<template>
  <TitleWrapper
    :filter-option="true"
    :title="['section_student', 'guardian'].includes(activeRole) ? 'PAID' : 'RECEIVED'"
    tooltip-title="RECEIVED_TOOLTIP"
    :display-breadcrumb="true"
    :count="filtersCount"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
    @cancel="cancelHanlder"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiMultiSelect
          v-model.trim="filtersPayload.student_ids"
          title="STUDENTS"
          :options="studentList"
          reduce="id"
          label="full_name"
          class="flex-1 mb-5 sm:mb-0"
          input-color="bg-white"
          :already-selected="selectedStudents"
          :search-through-api="true"
          :filter="FILTERS_LIST.STUDENTS"
          @search="getStudents"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model.trim="filtersPayload.title"
          class="flex-1"
          title="TITLE"
          type="text"
          placeholder="TITLE"
          :filter="FILTERS_LIST.TITLE"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.inserted_at_date_after"
          title="CREATED_AT_AFTER"
          :class="$style.widthItems"
          class="flex-1"
          :date-mode="DATE_MODE.START_DATE_TIME"
          :filter="FILTERS_LIST.INSERTED_DATE_GREATER_THAN_EQUAL"
          @emitQuery="updateQuery"
          @change="createdDateAfter"
        />
        <UiDatePicker
          v-model="filtersPayload.inserted_at_date_before"
          title="CREATED_AT_BEFORE"
          :class="$style.widthItems"
          class="flex-1"
          :date-mode="DATE_MODE.END_DATE_TIME"
          :filter="FILTERS_LIST.INSERTED_DATE_LESS_THAN_EQUAL"
          @emitQuery="updateQuery"
          @change="createdDateBefore"
        />
        <UiSingleSelect
          v-model="filtersPayload.status"
          title="P_STATUS"
          :options="paymentStatus"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="name"
          reduce="value"
          :filter="{
            option: FILTER_KEYS.EQUAL,
            key: 'status',
          }"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.status_by_due_date"
          title="DUE_DATE_STATUS"
          :options="dueDateStatus"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="name"
          reduce="value"
          :filter="{
            option: FILTER_KEYS.EQUAL,
            key: 'status_by_due_date',
          }"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.fee_amount_greater_than"
          v-model:secondary="filtersPayload.fee_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="Fee Amount"
          type="range"
          rules="numeric"
          :filter="FILTERS_LIST.FEE_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.paid_amount_greater_than"
          v-model:secondary="filtersPayload.paid_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="PAID_AMOUNT"
          type="range"
          placeholder="PAID_AMOUNT"
          rules="numeric"
          :filter="FILTERS_LIST.PAID_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />

        <UiInput
          v-model:initial="filtersPayload.payable_amont_greater_than"
          v-model:secondary="filtersPayload.payable_amont_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="Payable Amount"
          type="range"
          placeholder="Payable Amount"
          rules="numeric"
          :filter="FILTERS_LIST.PAYABLE_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.fine_amount_greater_than"
          v-model:secondary="filtersPayload.fine_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="FINE_AMOUNT"
          type="range"
          placeholder="FINE_AMOUNT"
          rules="numeric"
          :filter="FILTERS_LIST.FINE_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.discount_amount_greater_than"
          v-model:secondary="filtersPayload.discount_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="DISCOUNT_AMOUNT"
          type="range"
          placeholder="DISCOUNT_AMOUNT"
          rules="numeric"
          :filter="FILTERS_LIST.DISCOUNT_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.fee_type_id"
          title="FEE_TYPE"
          :options="feeTypes"
          :class="$style.widthItems"
          class="flex-1"
          input-color="bg-white"
          reduce="id"
          :label="'title'"
          :filter="{
            option: FILTER_KEYS.EQUAL,
            key: 'fee_type_id',
          }"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.paid_after"
          title="PAID_AFTER"
          :class="$style.widthItems"
          :date-mode="DATE_MODE.START_DATE_TIME"
          class="flex-1"
          :filter="FILTERS_LIST.PAID_DATE_GREATER_THAN_EQUAL"
          @emitQuery="updateQuery"
          @change="paidDateAfter"
        />
        <UiDatePicker
          v-model="filtersPayload.paid_before"
          title="PAID_BEFORE"
          :class="$style.widthItems"
          class="flex-1"
          :date-mode="DATE_MODE.END_DATE_TIME"
          :filter="FILTERS_LIST.PAID_DATE_LESS_THAN_EQUAL"
          @emitQuery="updateQuery"
          @change="paidDateBefore"
        />
        <UiDatePicker
          v-model="filtersPayload.due_date_after"
          title="DUE_AFTER"
          :class="$style.widthItems"
          class="flex-1"
          :date-mode="DATE_MODE.START_DATE_TIME"
          :filter="FILTERS_LIST.DUE_DATE_GREATER_THAN_EQUAL"
          @emitQuery="updateQuery"
          @change="dueDateAfter"
        />
        <UiDatePicker
          v-model="filtersPayload.due_date_before"
          title="DUE_BEFORE"
          :class="$style.widthItems"
          class="flex-1"
          :date-mode="DATE_MODE.END_DATE_TIME"
          :filter="FILTERS_LIST.DUE_DATE_LESS_THAN_EQUAL"
          @emitQuery="updateQuery"
          @change="dueDateBefore"
        />
        <UiMultiSelect
          v-if="showReceivedByFilter"
          v-model.trim="filtersPayload.received_by_ids"
          title="RECEIVED_BY"
          label="full_name"
          :options="receiverList"
          :already-selected="selectedReceivers"
          class="flex-1"
          reduce="id"
          :image="true"
          input-color="bg-white"
          :search-through-api="true"
          :filter="{ option: FILTER_KEYS.IN, key: 'received_by_ids' }"
          @emitQuery="updateQuery"
          @search="loadRecieverList"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { formatDateString } from '@utils/moment.util'
import feeConstants from '@src/constants/fee-constants'
import { DATE_MODE } from '@/src/constants/date-time-constants.js'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import filterMixins from '@/src/mixins/filter-mixins.js'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@/src/utils/filters'
import { mapActions, mapState } from 'vuex'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiSingleSelect,
    UiMultiSelect,
    TitleWrapper,
    UiDatePicker,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
    showReceivedByFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return this.initFiltersState()
  },

  computed: {
    paymentStatus() {
      let response
      if (this.showReceivedByFilter) {
        response = this.status.filter((status) => status.value !== 'waived')
      } else response = this.status
      return response
    },
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope.id,
    }),
    ...mapState({ activeRole: (state) => state.layout.activeRole }),
  },
  watch: {
    showReceivedByFilter: {
      handler(val) {
        if (val) {
          this.clearFilters()
        }
      },
    },
    currentCampusScope: {
      handler(value) {
        if (value) {
          this.clearFilters()
          this.loadRecieverList()
          this.getStudents()
        }
      },
    },

    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.loadRecieverList()
        this.getStudents()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.loadData()
    this.loadRecieverList()
    this.getStudents()
  },
  methods: {
    initFiltersState() {
      return {
        status: feeConstants.receivedStatus,
        dueDateStatus: feeConstants.receivableDueStatus,
        feeTypes: [],
        GENERAL_CONSTANTS,
        filtersCount: 0,
        studentList: [],
        $where: {},
        selectedReceivers: [],
        receiverList: [],
        selectedStudents: [],
        DATE_MODE,
        sectionStudents: [],
        queries: {},
        FILTER_KEYS,
        FILTERS_LIST,
        filtersPayload: {
          inserted_at_date_after: '',
          inserted_at_date_before: '',
          title: '',
          student_ids: [],
          received_by_ids: [],
          fee_type_id: '',
          status: '',
          status_by_due_date: '',
          fine_amount_greater_than: '',
          fine_amount_less_than: '',
          discount_amount_greater_than: '',
          discount_amount_less_than: '',
          fee_amount_greater_than: '',
          fee_amount_less_than: '',
          paid_amount_greater_than: '',
          paid_amount_less_than: '',
          payable_amont_greater_than: '',
          payable_amont_less_than: '',
          due_date_after: '',
          due_date_before: '',
          paid_before: '',
          paid_after: '',
          displayedColumns: [],
        },
      }
    },
    loadRecieverList(query = '') {
      let notAllowedRoles = [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN]
      if (notAllowedRoles.includes(this.activeRole)) return

      let alreadySelectedRecievers = []
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', this.campus?.id),
        },
      }
      removeEmptyKeysFromObject(payload.$where)
      this.getStaffWithFilters(payload).then((response) => {
        this.receiverList = response?.data?.records
        if (response) {
          if (this.filtersPayload && this.filtersPayload.received_by_ids.length) {
            this.filtersPayload.received_by_ids.forEach((id) => {
              alreadySelectedRecievers = this.receiverList.filter((receiver) => receiver.id === id)
            })
          }
          this.selectedReceivers = alreadySelectedRecievers
        }
      })
    },

    getFiltersCount() {
      this.loadRecieverList()
      this.getStudents()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },

    loadData() {
      this.getFeeTypes().then((res) => {
        this.feeTypes = res.data.records
      })
    },
    getStudents(query) {
      let notAllowedRoles = [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN]
      if (notAllowedRoles.includes(this.activeRole)) return

      let alreadySelectedStudents = []
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getStudentsWithFilters(payload).then((res) => {
        this.studentList = res?.records
        this.studentList?.forEach((record) => {
          if (this.filtersPayload && this.filtersPayload.student_ids.length) {
            this.filtersPayload.student_ids.forEach((id) => {
              if (record.id === id) {
                alreadySelectedStudents.push(record)
              }
            })
          }
          this.selectedStudents = alreadySelectedStudents
        })
      })
    },

    ...mapActions('fee', ['getFeeTypes']),
    ...mapActions('staff', ['getStaffWithFilters']),
    ...mapActions('student', ['getStudentsWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
